import React, { useRef } from 'react'
import { Container,Row, Col } from 'reactstrap'
import { Link, NavLink } from 'react-router-dom'
import './header.css'
import Logo from '../../assets/Images/Logo.png'

const navLinks = [
  {
    path:'/home',
    display:'Home'
  },
  {
    path:'/about',
    display:'Sobre Nosotros'
  },
  {
    path:'/carparts',
    display:'Lista de productos'
  },
  /*{
    path:'/blog',
    display:'Ubicación'
  },*/
]


const Head = () => {

  const menuRef = useRef(null)
  const toggleMenu = () => menuRef.current.classList.toggle('menu__active')  

  return <header className="header">
    {/*Header Top*/}
    <div className="header__top">
      <Container>
        <Row>
          
          {/*Header Right*/}
          <Col lg='12' md='6' sm='6'>
            <div className="header__top__right d-flex align-items-center justify-content-end gap-3">
              <Link to='/login' className='d-flex align-items-center gap-1'>
                <i className="ri-user-fill"></i> Login
              </Link> 
            </div>
          </Col>
        </Row>
      </Container>
    </div>

    
    {/*Header Middle*/}
    <div className="header__top__middle">
        <Container>
          <Row>
            <Col lg='4' md='3'  sm='4'>
              <div className='Logo'>
                <h1>
                <Link to='/home' className=' d-flex align-items-center gap-3'>
                <img src={Logo} className="Logo-Car" alt='carLogo'/>
                {/*Por si quiero poner algun texto debajo del logo */}
                </Link>
                </h1>
              </div>
            </Col>

            <Col lg='3' md='3' sm='4'>
              <div className="header__location d-flex align-items-center gap-2">
                <i className="ri-earth-fill"></i>
                <div className="header__location__content">
                  <h4>Santiago, República Dominicana</h4>
                  <h6>C. 2da 85-95, Santo Domingo Este 11510</h6>
                </div>
              </div>
            </Col>

            <Col lg='3' md='3' sm='4'>
              <div className="header__location d-flex align-items-center gap-2">
                <i className="ri-time-fill"></i>
                <div className="header__location__content header__horario">
                  <h4>Lunes a Viernes</h4>
                  <h6>8:00 a.m - 5:00 pm</h6>
                </div>
              </div>
            </Col>

            <Col lg='2' md='3' sm='0'>
            <button className="header__btns btn d-flex align-items-center justify-content-between text-end">
                <Link to='tel:+18098345151'>
                <i className="ri-phone-line"></i>Contacto 
                (809) 834 5151
                </Link>
              </button>
            </Col>

          </Row>

        </Container>
      </div>

      {/*Main Navigation*/}

      <div className="main__navbar">
        <Container>
          <div className="navigation__wrapper d-flex align-items-center justify-content-between">
            <span className="mobile__menu">
            <i className="ri-menu-line" onClick={toggleMenu}></i>
            </span>

            <div className="navigation" ref={menuRef} onClick={toggleMenu}>
              <div className="menu">
                {
                  navLinks.map((item,index) =>( 
                    <NavLink to={item.path} className={navClass => navClass.isActive ? 'nav__active nav__item' : 'nav__item'} key={index} >{item.display}</NavLink>
                  ))
                }
              </div>  
            </div>

            <div className="nav__right">
                <div className="search">
                  <input type="text" placeholder='Buscar' />
                  <span><i className="ri-search-line"></i></span>
                </div>
            </div>

          </div>
        </Container>
      </div>
  </header>
}

export default Head