
import React from 'react'
import Slider from 'react-slick'
import { Container } from 'reactstrap'
import { Link } from 'react-router-dom'
import '../../styles/hero-slider.css'

const settings ={
  fade: true,
  speed: 2000,
  autoplaySpeed: 5000,
  infinite: true,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  pauseOnHover: false,
};

const textBanner = 'Venta de repuestos usados y nuevos, originales y de remplazo para vehículos modernos';
const upText = ''

const HeroSlider = () => {
  return <Slider  {...settings} className="hero__slider">

    <div className="slider__item slider__item-01 mt0">
      <img src="../../assets/Images/ImagesSlider/Slider01.jpg" alt="" />
        <Container>
            <div className="slider__content"> 
                <h6 className="text-light mb3">{upText}</h6 >
                <h1 className="text-light mb3">{textBanner}</h1>
                <button className="btn reserve__btn mt-4">
                  <Link to='/carparts'>Mirar ahora</Link>
                </button>
            </div>
        </Container>
    </div>

    <div className="slider__item slider__item-02 mt0">
        <Container>
            <div className="slider__content">
                <h6 className="text-light mb3">{upText}</h6 >
                <h1 className="text-light mb3">{textBanner}</h1>
                <button className="btn reserve__btn mt-4">
                  <Link to='/carparts'>Mirar ahora</Link>
                </button>
            </div>
        </Container>
    </div>

    <div className="slider__item slider__item-03 mt0">
        <Container>
            <div className="slider__content">
                <h6 className="text-light mb3">{upText}</h6 >
                <h1 className="text-light mb3">{textBanner}</h1>
                <button className="btn reserve__btn mt-4">
                  <Link to='/carparts'>Mirar ahora</Link>
                </button>
            </div>
        </Container>
    </div>
  </Slider>
}

export default HeroSlider