import React from 'react'
import HeroSlider from '../components/UI/HeroSlider'
import Helmet from '../components/Helmet/Helmet'
import { Container, Row, Col } from 'reactstrap';
import LastestAcq from '../components/UI/LastestAcq';
import AboutSection from '../components/UI/AboutSection';
import ShowProducts from '../components/UI/ShowProducts';
import '../styles/lastAcq.css'



const Home = () => {
  
  return <Helmet title='Home'>
    <section className="p-0 hero__slider-section">
      <HeroSlider />

      <div className="hero__form">
        <Container>
          <Row className="form__row">
            <Col lg='4' md='4'>
              <div className="find__car-left">
              <h1><b>Últimas novedades</b></h1>
              </div>
            </Col>

            <Col lg='8' md='8' sm='12'>
              <LastestAcq />
            </Col>
          </Row>  
        </Container>
         
      </div>
    </section>

    {/*ABOUT SECTION*/}
    <AboutSection />

    {/*HOT OFFERS*/}
    
    <section>
      <Container>
        <Row>
          <Col lg='12' className='text-center mb-15'>
            <h6 className="section__subtitle" style={{color: '#f92626 !important' }}>Venga a ver</h6>
            <h2 className="section__title">Nuestras ofertas</h2>
            <ShowProducts />
          </Col>
        </Row>
      </Container>
    </section>

  </Helmet>
};

export default Home
