import React from 'react'
import '../../styles/lastAcq.css'
import { Form /*FormGroup*/ } from 'reactstrap'


const LastestAcq = () => {
  return <Form>
    <div className="form d-flex align-items-center justify-content-between flex-wrap">
      
    </div>
  </Form>
}

export default LastestAcq