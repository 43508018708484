import axios from "axios"
import React, { useState ,useEffect } from "react";
import { Link, useLocation } from 'react-router-dom'
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import "../styles/admin-home.css"

const URI = 'https://datos.sysautorepuestos.com';
const URI2 = 'https://datos.sysautorepuestos.com/emails/';

const AdminHome = () => {

  //const {state} = useLocation();
  const [product, setProduct] = useState([]);
  const [user, setUser] = useState([]);
  const [modalEliminar, setModalEliminar]= useState(false);
  const [modalEditar, setModalEditar]= useState(false);
  const [modalEliminarUser, setModalEliminarUser]= useState(false);
  const [modalEditarUser, setModalEditarUser]= useState(false);
  const [productSelected, setProductSelected] = useState({
    id: '',
    nombre: '',
    descripcion: '',
    marca: '',
    precio: '',
    userCreated: '',
    userUpdated: '',
  })
  const [userSelected, setUserSelected] = useState({
    id: '',
    nombre: '',
    email: '',
    password: '',
  })
    useEffect(() => {
        getProducts();
        getUsers();
        
    },[])

    const abrirCerrarModalEliminar=()=>{
      setModalEliminar(!modalEliminar);
    }
    const abrirCerrarModalEditar=()=>{
      setModalEditar(!modalEditar);
    }
    const abrirCerrarModalEliminarUser=()=>{
      setModalEliminarUser(!modalEliminarUser);
    }
    const abrirCerrarModalEditarUser=()=>{
      setModalEditarUser(!modalEditarUser);
    }

    //Mostar productos
    const getProducts = async () => {
        const res = await axios.get(URI);
        setProduct(res.data)
    }

    //Mostrar usuarios
    const getUsers = async () => {
      const res = await axios.get(URI2);
      setUser(res.data)
  }

    //Selecionar producto y acción
    const selectProduct=(item, caso)=>{
      setProductSelected(item);
  
      (caso==="Edit")?
      putProduct():
      abrirCerrarModalEliminar()
    }

    //Seleccionar usuario y acción
    const selectUser=(item, caso)=>{
      setUserSelected(item);
  
      (caso==="Edit")?
      putProduct():
      abrirCerrarModalEliminarUser()
    }

    const handleChange = e =>{
      const {name, value}=e.target;
      setProductSelected((prevState)=>({
        ...prevState,
        [name]: value
      }))
      console.log(productSelected);
    }

    //Pasar datos de producto seleccionado para editar
    const putProduct = async () => {
      var f = new FormData();
      f.append("nombre", productSelected.nombre);
      f.append("descripcion", productSelected.descripcion);
      f.append("marca", productSelected.marca);
      f.append("precio", productSelected.precio);
      /*f.append("imagen", productSelected.imagen);
      f.append("imagen1", productSelected.imagen1);
      f.append("imagen2", productSelected.imagen2);
      f.append("imagen3", productSelected.imagen3);
      f.append("imagen4", productSelected.imagen4);*/
      f.append("METHOD", "PUT");
      await axios.post(URI, f, {params: {id: productSelected.id}})
      .then(response=>{
        var dataNueva = product;
      dataNueva.map(item => {

        if(item.id===productSelected.id){
          item.nombre = productSelected.nombre;
          item.descripcion = productSelected.descripcion;
          item.marca = productSelected.marca;
          item.precio = productSelected.precio;
        }
      });
      setProduct(dataNueva);
      abrirCerrarModalEditar();
      }).catch(error=>{
        console.log(error);
      })
    }
    

    //Eliminar un producto
    const deleteProduct = async () => {
      var f = new FormData();
      f.append("METHOD", "DELETE");
      await axios.post(URI, f, {params: {id: productSelected.id}})
      .then(response=>{
        setProduct(product.filter(item => item.id !== productSelected.id));
        abrirCerrarModalEliminar()
      }).catch(error=>{
        console.log(error);
      })
    }

    //Eliminar un usuario
    const deleteUser = async () => {
      var f = new FormData();
      f.append("METHOD", "DELETE");
      await axios.post(URI2, f, {params: {id: userSelected.id}})
      .then(response=>{
        setUser(user.filter(item => item.id !== userSelected.id));
        abrirCerrarModalEliminarUser()
      }).catch(error=>{
        console.log(error);
      })
    }

    //Cambiar entre usuarios y productos
    const handleAdmin = (caso) => {
      if(caso === 'Usuarios'){
        document.getElementById('usuarios').style.display = 'block';
        document.getElementById('productos').style.display = 'none';
      }else{
        document.getElementById('usuarios').style.display = 'none';
        document.getElementById('productos').style.display = 'block';
      }
    }

  return (
    <>

    {/*Administrar Productos*/}
    <div id='productos' style={{display: 'block'}}>
    <Link to='/addProduct'><button className="btn btn-success btn-lg add__btn">Agregar nuevo producto</button>
    </Link>
    <button className="btn btn-primary btn-lg add__btn" onClick={e => handleAdmin('Usuarios')}>Administrar Usuarios</button>
    <h1>PRODUCTOS</h1>
    
    <table className="table table-striped data__table">
      <thead>

        <tr>
          <th>ID</th>
          <th>Nombre</th>
          <th className="onlyOnPC">Descripción</th>
          <th>Marca</th>
          <th>Precio</th>
          <th className="onlyOnPC">Subido por</th>
          <th className="onlyOnPC">Editado por</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
        {product.map(item => (
          <tr key={item.id}>
            <td>{item.id}</td>
            <td>{item.nombre}</td>
            <td className="onlyOnPC">{item.descripcion}</td>
            <td>{item.marca}</td>
            <td>{item.precio}</td>
            <td className="onlyOnPC">{item.userCreated}</td>
            <td className="onlyOnPC">{item.userUpdated}</td>
            <td>
              <button className="btn__table btn btn-primary " onClick={()=>selectProduct(item, "Edit")}>Editar</button>
              <button className="btn__table btn btn-danger" onClick={()=>selectProduct(item, "Delete")}>Eliminar</button>
            </td>
          </tr>
        ))}


      </tbody>

    </table>
    </div>

    {/*Administrar Usuarios*/}
    <div id='usuarios' style={{display: 'none'}}>
    <button className="btn btn-success btn-lg add__btn">Agregar nuevo usuario</button>
    <button className="btn btn-primary btn-lg add__btn" onClick={e => handleAdmin('Productos')}>Administrar Productos</button>
    <h1>USUARIOS</h1>
    
    <table className="table table-striped data__table">
      <thead>

        <tr>
          <th>ID</th>
          <th>Nombre</th>
          <th>Correo</th>
          <th>Contraseña</th>
        </tr>
      </thead>
      <tbody>
      {user.map(item => (
          <tr key={item.id}>
            <td>{item.id}</td>
            <td>{item.nombre}</td>
            <td>{item.email}</td>
            <td>{item.password}</td>
            <td>
              <button className="btn__table btn btn-primary " onClick={()=>selectUser(item, "Edit")}>Editar</button>
              <button className="btn__table btn btn-danger" onClick={()=>selectUser(item, "Delete")}>Eliminar</button>
            </td>
          </tr>
        ))}


      </tbody>

    </table>
    </div>

    <Modal isOpen={modalEditar}>
      <ModalHeader>Editar Producto</ModalHeader>
      <ModalBody>
        <div className="form-group">
          <label>Nombre: </label>
          <br />
          <input type="text" className="form-control" name="nombre" onChange={handleChange} value={productSelected && productSelected.nombre}/>
          <br />
          <label>Descripción: </label>
          <br />
          <input type="text" className="form-control" name="descripcion" onChange={handleChange} value={productSelected && productSelected.descripcion}/>
          <br />
          <label>Marca: </label>
          <br />
          <input type="text" className="form-control" name="marca" onChange={handleChange} value={productSelected && productSelected.marca}/>
          <br />
          <label>Precio: </label>
          <br />
          <input type="number" className="form-control" name="precio" onChange={handleChange} value={productSelected && productSelected.precio}/>
          <br />
        </div>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-primary" onClick={()=>putProduct()}>Editar</button>{"   "}
        <button className="btn btn-danger" onClick={()=>abrirCerrarModalEditar()}>Cancelar</button>
      </ModalFooter>
    </Modal>
    
    <Modal isOpen={modalEliminar}>
        <ModalBody>
        ¿Estás seguro que quieres eliminar: {productSelected && productSelected.nombre}?
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-danger" onClick={()=>deleteProduct()}>
            Sí
          </button>
          <button
            className="btn btn-secondary"
            onClick={()=>abrirCerrarModalEliminar()}
          >
            No
          </button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalEliminarUser}>
        <ModalBody>
        ¿Estás seguro que quieres eliminar: {userSelected && userSelected.nombre}?
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-danger" onClick={()=>deleteUser()}>
            Sí
          </button>
          <button
            className="btn btn-secondary"
            onClick={()=>abrirCerrarModalEliminarUser()}
          >
            No
          </button>
        </ModalFooter>
      </Modal>

    </>
  )
}

export default AdminHome