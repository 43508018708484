import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage"


const firebaseConfig = {
  apiKey: "AIzaSyCKL_I9W7-18TYM-ejOmuNOhpa_ozCmK-s",
  authDomain: "sysproject-ab9ff.firebaseapp.com",
  projectId: "sysproject-ab9ff",
  storageBucket: "sysproject-ab9ff.appspot.com",
  messagingSenderId: "491496085951",
  appId: "1:491496085951:web:0baa36e3a17c95d036a90a"
};

const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);

export async function uploadImages (file, nombre) {
    
    const storageRef = ref(storage, nombre)
    await uploadBytes(storageRef, file)
    const url = await getDownloadURL(storageRef)
    return url
}

