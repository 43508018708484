
import React from 'react'
import { Container, Row, Col, ListGroup, ListGroupItem } from 'reactstrap'
import { Link } from 'react-router-dom'
//import Logo from '../../assets/Images/Logo.png'
import './footer.css'
import BackToTop from './BackToTop'

const quicklinks = [
  {
    path:'/about',
    display:'Sobre nosotros'
  },
  {
    path:'/carparts',
    display:'Lista de productos'
  },
  /*{
    path:'/blog',
    display:'Ubicacion'
  },*/
]

const Footer = () => {
  return <footer className="footer">
    
      <Container>
        <Row>
          <BackToTop />
          {/*<Col lg='4' md='4' sm='12'>
          <div className='footer__logo'>
                <h1>
                <img src={Logo} className="Logo-Car" alt='carLogo'/>
                </h1>
              </div> 
              <p className="footer__logo-content">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis praesentium corrupti consequatur quas recusandae laborum temporibus beatae quisquam amet similique, itaque, quis sint illum iste molestias vitae sequi harum quae.
              </p>
          </Col>*/}

          <Col lg='2' md='4' sm='6'>
            <div className="mb-4">
              <h5 className="footer__link-tittle">Enlaces rapidos</h5>
              <ListGroup>
                {
                  quicklinks.map((item, index) => (
                    <ListGroupItem key={index} className='p-0 mt-3 footer__links'>
                      <Link to={item.path}>{item.display}</Link>
                    </ListGroupItem>
                  ))
                }
              </ListGroup> 
            </div>
          </Col>
          
          <Col lg='3' md='4' sm='6'>
            <div className="mb-4">
              <h5 className="footer__link-tittle mb-4">Información</h5>
              <p className="office__info">Información sobre el local</p>
              <p className="office__info">Telefono: (809) 834 5151</p>
              <p className="office__info">Correo: victor@sysautorepuestos.com</p>
              <p className="office__info">Horario: 8:00 a.m - 5:00 pm </p>
            </div>
          </Col>

          <Col lg='3' md='4'>
            <div className="social__media mb-4">
              <h5 className="footer__link-tittle">Redes Sociales</h5>
              <button><Link to='https://api.whatsapp.com/send/?phone=18098345151&text&type=phone_number&app_absent=0'>
                <i className="ri-whatsapp-line"/></Link>
              </button>
                
              <button><Link to='https://www.facebook.com/profile.php?id=61550734435843'>
                <i className="ri-facebook-line"/></Link>
              </button>

              <button><Link to='/#'>
                <i className="ri-instagram-line"/></Link>
              </button>

            </div>
          </Col>
        </Row>
      </Container>
  </footer>
}

export default Footer