import React from 'react'
import { useEffect,useState } from 'react'
import './backToTop.css'

function BackToTop(){
    const [backToTop, setBackToTop] = useState(false);

    useEffect (() => {
        window.addEventListener("scroll", () => {
            if(window.scrollY > 100){
                setBackToTop(true)
            }else{
                setBackToTop(false)
            }
        })
    })

    const scrollUp = () =>{
        window.scrollTo({
            top:0,
            behavior: "smooth"
        })
    }

    return <div className='btn__top'>
        {backToTop && (
            <button onClick={scrollUp}><i className="ri-arrow-up-s-line"></i> VOLVER ARRIBA <i className="ri-arrow-up-s-line"></i></button>
        )}
    </div>
}

export default BackToTop