import React, { useState ,useEffect } from 'react';
import axios from 'axios';
import Helmet from "../components/Helmet/Helmet";
//import CommonSection from "../components/UI/CommonSection";
import { Container, Row, Col } from "reactstrap";
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import "../styles/part-details.css";

const URI = 'https://datos.sysautorepuestos.com';

const PartDetails = () => {

  const [product, setProduct] = useState([]);
    useEffect(() => {
        window.scroll(0, 0);
        getProducts();
        
    },[])

  //Mostar productos
  const getProducts = async () => {
    const res = await axios.get(URI);
    setProduct(res.data);
}
    

  const { slug } = useParams();
  const singleItem = product.find((item) => item.nombre === slug);

  if(singleItem !== undefined){
    const wppcontact = `https://wa.me/18098345151?text=Estoy interesado en comprar ${singleItem.nombre}`;
    return <Helmet title={singleItem.nombre} className='single__item-back'>
    <section className='single__item'>
      <Container>
        <Row>

          <div className="single__item-title">
          <h2 className="section__title">{singleItem.nombre}</h2>
          </div>

          <Col lg="6">
            <img src={singleItem.imagen} alt="" className="w-100" />
          </Col>

          <Col lg="6">

            <div className="single__item-info">

              <div className=" d-flex align-items-center gap-5 mb-4 mt-3">
                <h6 className="single__item-price fw-bold fs-4">
                  RD$ {singleItem.precio}.00
                </h6>
              </div>

              <i className="ri-file-text-fill">Descripción</i>
              <p className="section__description">
                {singleItem.descripcion}
              </p>
              <p><b>Marca: </b>{singleItem.marca}</p>

            </div>

            <div>
            <h4><b>Cotizar pieza</b></h4>
            <Link to={wppcontact}>
              <button className='single__item-btn wpp__btn'><i className="ri-whatsapp-line"></i> Whatsapp</button>
            </Link>
            <Link to='tel:+18098345151'>
              <button className='single__item-btn call__btn'><i className="ri-phone-line"></i> Llamada</button>
            </Link>
            </div>

          </Col>

        </Row>
      </Container>
    </section>
  </Helmet>

  }else{
    return(
      <div>
        <h1>CARGANDO...</h1>
        {console.log(singleItem)}
      </div>
    )
  }
}

export default PartDetails