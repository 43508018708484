import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate/*, useParams*/ } from 'react-router-dom'
import { useForm } from '../components/Hook/useForm'
import '../styles/login.css'


const URI = 'https://datos.sysautorepuestos.com/emails/';
const Login = () => {

  const [product, setProduct] = useState([]);
  useEffect(() => {
      window.scroll(0, 0);
      getProducts();
      
  },[])

//Mostar productos
const getProducts = async () => {
  const res = await axios.get(URI);
  setProduct(res.data);
}
  

//const { slug } = useParams();
const users = product.find((item) => item.id);


  const navigate = useNavigate();

	const { email, password, onInputChange, onResetForm } =
		useForm({
			email: '',
			password: '',
		});

	const onLogin = e => {

		if(email === users.email && password === users.password){
      if(email === 'victorcruz002@gmail.com'){
        const nombre = users.nombre;
      navigate(`/adminhome`, {
        replace: true,
        state: {
          logged: true,
          email,
          password,
          nombre,
        },
      });
      }else{
        const nombre = users.nombre;
      navigate(`/userhome`, {
        replace: true,
        state: {
          logged: true,
          email,
          password,
          nombre,
        },
      });
      }
    }else{
      alert("El correo y la contraseña no concuerdan")
      e.preventDefault();
    }

		onResetForm();
	};


  if (users === undefined) {
    return (
      <div>
        <h1>CARGANDO...</h1>
      </div>
    );
  }else{
    return( <div className="login">
    <section>
    <form className='login__form' onSubmit={onLogin}>
          <p>Correo</p>
          <div className="login__data">
            <input type="email" 
            name='email'
						id='email'
						value={email}
						onChange={onInputChange}
						required
						autoComplete='off'/>
          </div>
          
          <p>Contraseña</p>
          <div className="login__data">
          <input type="password"
            name='password'
            id='password'
            value={password}
            onChange={onInputChange}
            required
            autoComplete='off'/>
          </div>
          <button className="login__btn">Ingresar</button>
        </form>
    </section>
  </div>
  );
  }
}

export default Login