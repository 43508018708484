import React from 'react'
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import { Container, Row, Col } from "reactstrap";
import ShowProductsAll from '../components/UI/ShowProductsAll';



const CarPartsListing = () => {
  return <Helmet title="Productos">
    <CommonSection title="Lista de Productos" />
    
    <section>
      <Container>
        <Row>
          <Col lg='12'>
            <ShowProductsAll/>
          </Col>
        </Row>
      </Container>
    </section>
  </Helmet>
}

export default CarPartsListing
