import React from 'react'

const Helmet = (props) => {
    function namePage(){
      if(props.title === 'Home'){
        document.title = 'SYS';
      }else{
        document.title = `${props.title} | SYS`;
      }
    }
  return (
    <div className='w-100'>{namePage()}{props.children}</div>
  )
};

export default Helmet