import axios from "axios"
import React, { useState ,useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import { uploadImages } from "./UploadImages";
import "../../styles/addproduct.css"

const URI = 'https://datos.sysautorepuestos.com';
const AddingProducts = () => {

    const navigate = useNavigate();
    const [product, setProduct] = useState([]);
    const [image, setImage] = useState(null)
    const [image1, setImage1] = useState(null)
    const [image2, setImage2] = useState(null)
    const [image3, setImage3] = useState(null)
    const [image4, setImage4] = useState(null)
    const [productSelected, setProductSelected] = useState({
        id: '',
        nombre: '',
        descripcion: '',
        marca: '',
        precio: '',
        userCreated: 'Admin'
    })

    useEffect(() => {
        window.scroll(0, 0);
        getProducts();
        
    },[])

    const handleChange = e => {
        const {name, value}=e.target;
        setProductSelected((prevState)=>({
          ...prevState,
          [name]: value
        }))
        //console.log(productSelected);
      }

    //Subida de imagenes
    const handleImage = async e => {
        const imagen = await uploadImages(image, productSelected.nombre);
        return imagen

    }
    const handleImage1 = async e => {
        if(image1 !== null){
            const imagen = await uploadImages(image1, productSelected.nombre+'1');
            return imagen
        }else{
            return ''
        }

    }
    const handleImage2 = async e => {
        if(image2 !== null){
            const imagen = await uploadImages(image2, productSelected.nombre+'2');
            return imagen
        }else{
            return ''
        }
    }
    const handleImage3 = async e => {
        if(image3 !== null){
            const imagen = await uploadImages(image3, productSelected.nombre+'3');
            return imagen
        }else{
            return ''
        }

    }
    const handleImage4 = async e => {
        if(image4 !== null){
            const imagen = await uploadImages(image4, productSelected.nombre+'4');
            return imagen
        }else{
            return ''
        }

    }

    //Mostar productos
    const getProducts = async () => {
        const res = await axios.get(URI);
        setProduct(res.data)
    }

    function Charging(){

        document.getElementById('chargeIcon').style.display = 'block';
        document.getElementById('chargeIcon').style.marginTop = '-30px';
        document.getElementById('chargeIcon').style.marginBottom = '-60px';
    }

    //Agregar productos
    const postProduct = async (e) => {
        e.preventDefault();
        Charging();
        const imagen = await handleImage();
        const imagen1 = await handleImage1();
        const imagen2 = await handleImage2();
        const imagen3 = await handleImage3();
        const imagen4 = await handleImage4();
        var f = new FormData();
        f.append("nombre", productSelected.nombre);
        f.append("descripcion", productSelected.descripcion);
        f.append("marca", productSelected.marca);
        f.append("precio", productSelected.precio);
        f.append("imagen", imagen);
        f.append("imagen1", imagen1);
        f.append("imagen2", imagen2);
        f.append("imagen3", imagen3);
        f.append("imagen4", imagen4);
        f.append("userCreated", "Admin");
        f.append("METHOD", "POST");
        await axios.post(URI, f)
        .then(response=>{
          setProduct(product.concat(response.data));
          navigate('/adminhome');
        }).catch(error=>{
          console.log(error);
        })
      }



  return (
    <div className="add__product">
        <h1>AGREGANDO NUEVO PRODUCTO</h1>
        <section>
            <form className='add__form' onSubmit={postProduct}>
                
                <p>Nombre del producto:</p>
                <div className="add__data nombre">
                    <input type="text" 
                    name='nombre'
	        		onChange={handleChange}
	        		required
	        		autoComplete='off'/>
               </div>
          
                
                <p>Descripcion del producto:</p>
                <div className="add__data descripcion">
                 <textarea 
                 name='descripcion'
                 onChange={handleChange}
                 required
                 autoComplete='off'
                 cols="40" 
                 rows="10"/>
                </div>

                
                <p>Marca del producto:</p>
                <div className="add__data marca">
                   <input type="text"
                   name='marca'
                   onChange={handleChange}
                   required
                   autoComplete='off'/>
                </div>

                
                <p>Precio:</p>
                <div className="add__data precio">
                <input type="number"
                    name='precio'
                    onChange={handleChange}
                    required
                    autoComplete='off'/>
                </div>

                
                <p>Imagen de portada:</p>
                <div className="add__data imagen">
                <input type="file"
                    name=''
                    id=''
                    onChange={e => setImage(e.target.files[0])}
                    required
                    autoComplete='off'/>
                </div>

                
                <p>Imagen 1</p>
                <div className="add__data imagen">
                <input type="file"
                    name=''
                    id=''
                    onChange={e => setImage1(e.target.files[0])}
                    autoComplete='off'/>
                </div>

                
                <p>Imagen 2</p>
                <div className="add__data imagen">
                <input type="file"
                    name=''
                    id=''
                    onChange={e => setImage2(e.target.files[0])}
                    autoComplete='off'/>
                </div>

                
                <p>Imagen 3</p>
                <div className="add__data imagen">
                <input type="file"
                    name=''
                    id=''
                    onChange={e => setImage3(e.target.files[0])}
                    autoComplete='off'/>
                </div>

                
                <p>Imagen 4</p>
                <div className="add__data imagen">
                <input type="file"
                    name=''
                    id=''
                    onChange={e => setImage4(e.target.files[0])}
                    autoComplete='off'/>
                </div>

                
                <div className="add__finish">
                <button className="insert__btn btn btn-primary btn-lg" id="boton_add">Agregar Producto</button>
                
                <img src="https://usagif.com/wp-content/uploads/loading-4.gif" alt="" id="chargeIcon" style={{
                    width: '250px',
                    display: 'none'
                }} />

                </div>   
            </form>
        </section>
    </div>
  )
}

export default AddingProducts