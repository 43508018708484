import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import '../../styles/about-section.css'
import Imagen from '../../assets/Images/aboutSectionImg.jpg'


const AboutSection = () => {
  return <section>
    <Container>
        <Row>
            <Col lg='6' md='6' >
                <div className="about__section-content">
                    <h4 className="section__subtitle">Sobre nosotros</h4>
                    <h2 className='section__title'>Bienvenido a SYS auto repuestos</h2>
                    <p className="section__description">
                        Dedicados a la importación y venta de partes y repuestos, usados y nuevos,
                        para vehículos modernos, especialmente piezas del interior y exterior, piezas mecánicas, 
                        motores, accesorios, módulos, entre otros.
                    </p>

                    <h5>VALORES</h5>
                    <div className="about__section-item">
                        
                        <div className="about__section-itemList">
                        <p className="section__description d-flex align-items-center gap-2">
                            <i className="ri-checkbox-blank-circle-line"></i>
                            Honestidad
                        </p>

                        <p className="section__description d-flex align-items-center gap-2">
                            <i className="ri-checkbox-blank-circle-line"></i>
                            Compromiso
                        </p>
                        </div>

                        <div className="about__section-itemList">
                        <p className="section__description d-flex align-items-center gap-2">
                            <i className="ri-checkbox-blank-circle-line"></i>
                            Responsabilidad
                        </p>

                        <p className="section__description d-flex align-items-center gap-2">
                            <i className="ri-checkbox-blank-circle-line"></i>
                            Respeto
                        </p>
                        </div>
                        
                    </div>
                </div>
            </Col>
            
            <Col lg='6' md='6' >

                <div className="about__img">
                    <img src={Imagen} alt="" className='w-100'/>
                </div>    
            
            </Col>
        </Row>
    </Container>

  </section>
}



export default AboutSection