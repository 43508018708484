import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from '../pages/Home.jsx';
import AdminHome from '../pages/AdminHome.jsx';
import UserHome from '../pages/UserHome.jsx';
import About from '../pages/About.jsx';
import CarPartsListing from '../pages/CarPartsListing.jsx';
import PartDetails from '../pages/PartDetails.jsx';
import Blog from '../pages/Blog.jsx';
import AddingProducts from '../components/UI/AddingProducts.jsx';
import Login from '../pages/Login.jsx';
import NotFound from '../pages/NotFound.jsx';

const Routers = () => {
  return <Routes>
    <Route path='/' element={<Navigate to='/home'/>} />
    <Route path='/home' element={<Home/>} />
    <Route path='/adminhome' element={<AdminHome/>} />
    <Route path='/userhome' element={<UserHome/>} />
    <Route path='/about' element={<About/>} />
    <Route path='/carparts' element={<CarPartsListing/>} />
    <Route path='/carparts/:slug' element={<PartDetails/>} />
    <Route path='/blog' element={<Blog/>} />
    <Route path='/login' element={<Login/>} />
    <Route path='/addProduct' element={<AddingProducts/>} />
    <Route path='/*' element={<NotFound/>} />
  </Routes>
}


export default Routers