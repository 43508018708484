import axios from "axios"
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col } from "reactstrap";
import "../../styles/car-item.css"


const URI = 'https://datos.sysautorepuestos.com';

const ShowProducts = () => {

    const [product, setProduct] = useState([]);
    useEffect(() => {
        getProducts()
        
    },[])

    //Mostar productos
    const getProducts = async () => {
        const res = await axios.get(URI);
        setProduct(res.data)
    }
  return (
    <div>
      
      {
        product.slice(0, 6).map(item => (
          <Col lg="4" md="4" sm="6" className="mb-5">
          <div className="car__item">
            <div className="car__img">
              <img src={item.imagen} alt="" className="w-100" />
            </div>
    
            <div className="car__item-content mt-4">
              <h4 className="section__title text-center">{item.nombre}</h4>
              <h6 className="rent__price text-center mt-">
                RD$ {item.precio}.00
              </h6>
    
              <div className="car__item-info d-flex align-items-center justify-content-between mt-3 mb-4">
                <span className=" d-flex align-items-center gap-1">
                  <i className="ri-car-line"></i> {item.marca}
                </span>
                
              </div>
    
              
    
              <Link to={`/carparts/${item.nombre}`}>
              <button className=" w-50 car__item-btn car__btn-details">
                Detalles
              </button>
              </Link>
            </div>
          </div>
        </Col>
        ))
      }
    </div>
  )
}

export default ShowProducts