import React from "react";
import Helmet from "../components/Helmet/Helmet";
import AboutSection from "../components/UI/AboutSection";
import { Container, Row, Col } from "reactstrap";
import CommonSection from "../components/UI/CommonSection";
import driveImg from "../assets/Images/AboutImg.jpg";
//import { uploadImages } from '../components/Data/ManageData/UploadImages'
import "../styles/about.css";

const About = () => {
  return (
    <Helmet title="About">
    {/*<input type="file" name='' id='' onChange={e => uploadImages(e.target.files[0])} />*/}
    <CommonSection title="Sobre Nosotros" />
    <AboutSection aboutClass="aboutPage" />

    <section className="about__page-section">
      <Container>
        <Row>
          <Col lg="6" md="6" sm="12">
            <div className="about__page-img">
              <img src={driveImg} alt="" className=" w-100 rounded-3" />
            </div>
          </Col>

          <Col lg="6" md="6" sm="12">
           <div className="about__page-content">
             <h2 className="section__title">
               Servicios
             </h2>

               <p className="section__description">
                 Además de nuestro amplio catálogo de piezas, recibimos pedidos por encargo
                 de piezas de remplazo y originales directamente de distintos dealers de Miami, para que usted pueda
                 conseguir lo que quiere con efectividad e incluso de forma económica.

               </p>

               <p className="section__description">
                  Contamos también con un servicio de instalación de las piezas que vendemos para mayor
                  garantia.
               </p>

               <div className="container1">

                <div className=" d-flex align-items-center gap-3 mt-4">
                  <span className="fs-4">
                    <i className="ri-phone-line"></i>
                  </span>

                  <div>
                    <h6 className="section__subtitle">Contacto</h6>
                    <h4>(809) 834 5151</h4>
                  </div>
                </div>

                <div className=" d-flex align-items-center gap-3 mt-4">
                  <span className="fs-4">
                    <i className="ri-mail-line"></i>
                  </span>

                  <div>
                    <h6 className="section__subtitle">Correo</h6>
                    <h4>victor@sysautorepuestos.com</h4>
                  </div>
                </div>

              </div>

            </div>

          </Col>
        </Row>
      </Container>
    </section>

      <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-5 text-center">
              
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default About;